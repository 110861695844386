/** Pinepointers 4: Bully **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, positionText, hideText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Circ } from "gsap";

let globalState, data, preloadData, creatureNum = 0;
const totalCreatures = 9;

class PinepointersPage4 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[4];
        preloadData = globalState.assets[5];

        //refs
        this.fullscreenBg = null;

    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        setTimeout(() => {
            
            //1. animate highlighters
            gsap.to(this.refs.highlighterWrapperRef, 1, { scale: 1, ease: Circ.easeOut, delay: 0.5 });

            //3. Animate in Badge
            gsap.to(this.refs.bullyBadgeRef, 0.75, { x: 0, y: 0, rotate: 0, ease: Circ.easeOut, delay: 1 });

        }, globalState.transitionDuration);


        //position text
        positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);
        
        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Animate creatures
        creatureNum = 0;
        this.animateCreatures();


        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.text2, 
            globalState.cdnUrl + preloadData.background,
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        
        for(var i=1; i<=totalCreatures; i++){
            gsap.killTweensOf(this.refs["c"+i+"Ref"]);
        }

        window.removeEventListener("resize", this.updateDimensions);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);

        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");

        }
    }

    isTransitioning = () => {
        //pause animations
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 724
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 724, false);

    }

    animateCreatures = () => {
        creatureNum++;
        if(creatureNum > totalCreatures){
            creatureNum = 1;
        }
        gsap.set(this.refs["c"+creatureNum+"Ref"], {scale:1, x:"0", y:"0", opacity: 1});
        gsap.to(this.refs["c"+creatureNum+"Ref"], 2, {scale: 0, x: "-131px", y: "3px", delay: 1.5, onComplete: this.animateCreatures});
    }

    render() {

        return (

            <>

                <link rel="prefetch" href="/pinepointers/5" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/pinepointers/5" prevLocation="/pinepointers/3"
                    isTransitioning={this.isTransitioning} />
                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Pinepointers" />

                    <div className="text-wrapper">
                        <div className="text text--left-bottom" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "7vw", marginTop: "15vh" }} onClick={this.toggleTextDisplay} alt="By his own admission, Richard was a piece of work – “bully” is the first word he uses to describe his teenage self. Every Pine Pointer I met remembered him. He’d pushed around one of The Brothers, and dated The Beauty. He was an athlete, a woodsman, a ladies man, the guy you’d fear, loathe, and wish to have as a friend." />
                        </div>
                    </div>

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />
                        <div className="highlighterWrapper" ref="highlighterWrapperRef" style={{ left: '296px', top: '22px', width: '171px', height: '27px' }}>
                            <img src={globalState.cdnUrl + data.highlight} alt="highlight" className="highlight" />
                        </div>
                        <img src={globalState.cdnUrl + data.badge} className="badge bullyBadge" ref="bullyBadgeRef" alt="Badge" style={{ left: '14px', top: '0' }} />

                        <div className="creaturesWrapper">
                            <img className="creature" ref={"c1Ref"} src={globalState.cdnUrl + data.creature1} alt="" style={{width: "78px", height: "120px"}} />
                            <img className="creature" ref={"c2Ref"} src={globalState.cdnUrl + data.creature2} alt="" style={{width: "109px", height: "94px"}} />
                            <img className="creature" ref={"c3Ref"} src={globalState.cdnUrl + data.creature3} alt="" style={{width: "84px", height: "79px"}} />
                            <img className="creature" ref={"c4Ref"} src={globalState.cdnUrl + data.creature4} alt="" style={{width: "133px", height: "110px"}} />
                            <img className="creature" ref={"c5Ref"} src={globalState.cdnUrl + data.creature5} alt="" style={{width: "113px", height: "106px"}} />
                            <img className="creature" ref={"c6Ref"} src={globalState.cdnUrl + data.creature6} alt="" style={{width: "97px", height: "118px"}} />
                            <img className="creature" ref={"c7Ref"} src={globalState.cdnUrl + data.creature7} alt="" style={{width: "114px", height: "72px"}} />
                            <img className="creature" ref={"c8Ref"} src={globalState.cdnUrl + data.creature8} alt="" style={{width: "119px", height: "59px"}} />
                            <img className="creature" ref={"c9Ref"} src={globalState.cdnUrl + data.creature9} alt="" style={{width: "130px", height: "88px"}} />
                        </div>
                    </div>
                </div>
                )}
            </>
        );
    }


};

export default PinepointersPage4
